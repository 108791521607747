import React, { useState } from 'react'
import classNames from 'classnames'
import {
  Button,
  Container,
  Section,
  Seo,
  Zoom,
} from '@components'
import { CustomerPanel } from '../../components/site/customers'
import { FeatureList } from '../../components/site/features'
import { LiveMetricsImage } from '../../components/site/product'
import { LandingPageLayout } from '../../components/site/layout/landing-page-layout'
import { PillarPanel } from '../../components/site/pillars'
import { ThreatPanel } from '../../components/site/threats'
import { PinningImage, ArchitectureImage } from '../../components/site/product'
import { QuickstartGrid } from '../../components/site/quickstarts'
import { OfferDialog } from '../../components/site/offer'

const PillarsPage = ({ location }) => {
  const [isLearnDialogOpen, setIsLearnDialogOpen] = useState(false)
  const onCloseLearnDialog = () => setIsLearnDialogOpen(false)

  const LearnOffer = ({ className }) => {
    return (
      <div className={classNames('flex justify-center', className)}>
        <a 
          className='m-4 button button-secondary' 
          href='https://info.approov.io/hubfs/White%20Paper/API-Protection-EBook-v3.1.pdf'
          target='_blank'
          rel='noopener norefferer'
        >
          Learn More About API Security
        </a>
      </div>
    )
  }
  const [isTrialDialogOpen, setIsTrialDialogOpen] = useState(false)
  const onCloseTrialDialog = () => setIsTrialDialogOpen(false)

  const TrialOffer = ({ className }) => {
    return (
      <div className={classNames('flex justify-center', className)}>
        <Button
          className="m-4 button button-secondary"
          onClick={() => setIsTrialDialogOpen(true)}
        >
          Give Approov a Try!
        </Button>
      </div>
    )
  }

  return (
    <>
      <LandingPageLayout pathname={location.pathname}>
        <Section className="pt-16 pb-8 bg-white">
          <Container>
            <h1 className="mb-12 text-5xl text-center text-secondary-500">
              Five Pillars of Effective Mobile API Security
            </h1>
          </Container>

          <Container>
            <PillarPanel />
          </Container>

          <Container className="my-8">
            <h2 className="text-4xl text-center text-secondary-500">
              Some of the Businesses We Protect
            </h2>
            <CustomerPanel className="mb-8" />
          </Container>

          <Container className="mb-8">
            <h2 className="mt-12 text-4xl text-center text-secondary-500 lg:mt-12">
              How API Attacks Impact Your Business
            </h2>
            <ThreatPanel className="lg:px-16" />
          </Container>

          <Container className="mb-8">
            <h2 className="mt-12 text-4xl text-center text-secondary-500 lg:mt-12">
              Introducing Approov
            </h2>
            <h3 className="mt-8 text-2xl text-primary-500">
              How Approov Protects Your Business
            </h3>
            <ul>
              <li>
                Data breach mitigation - ensures only genuine mobile app
                instances, running in safe environments, can use your API.
              </li>
              <li>
                API abuse prevention - blocks all scripts, bots, modified apps
                and fake apps from exploiting your API.
              </li>
              <li>
                Instant protection - integrates easily into your existing
                technology stack.
              </li>
              <li>
                Fraud reduction - provides immunity from the most common API
                attacks.
              </li>
            </ul>
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <LearnOffer />
              <p className="mt-0 font-bold text-center">
                Free eBook • Generic API Security Backgrounder
              </p>
            </div>
          </Container>

          <Container className="mb-8">
            <h3 className="mt-8 text-2xl text-primary-500">
              How Approov Works
            </h3>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
              <div>
                <div>
                  <p>Approov consists of three components:</p>
                  <ul>
                    <li>
                      Approov cloud service which makes all security decisions
                      off device - delivers uncompromisable security
                    </li>
                    <li>
                      An SDK that drops into your iOS or Android app - delivers
                      fast deployment
                    </li>
                    <li>
                      Short-lived security tokens to authenticate API traffic at
                      your backend - delivers rapid time to protection
                    </li>
                  </ul>
                </div>
                <div className="hidden lg:block">
                  <p>
                    App and device integrity are checked repeatedly every five
                    minutes. Channel integrity is checked continuously.
                  </p>
                  <p>
                    Only apps that have been registered with the Approov service
                    and which meet the runtime environmental criteria are issued
                    with valid JWT Approov tokens.
                  </p>
                  <p>
                    Approov JSON Web Tokens (JWT) are signed with a custom
                    secret only known to the Approov cloud service and the
                    backend API. The secret is never contained within the app
                    itself, so it cannot be extracted.
                  </p>
                  <p>
                    App registration can be immediately added and revoked from
                    the Approov service, allowing tight control of which app
                    versions can access your API.
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Zoom>{ArchitectureImage}</Zoom>
              </div>
            </div>
            <div className="block lg:hidden">
              <p>
                App and device integrity are checked repeatedly every five
                minutes. Channel integrity is checked continuously.
              </p>
              <p>
                Only apps that have been registered with the Approov service and
                which meet the runtime environmental criteria are issued with
                valid JWT Approov tokens.
              </p>
              <p>
                Approov JSON Web Tokens (JWT) are signed with a custom secret
                only known to the Approov cloud service and the backend API. The
                secret is never contained within the app itself, so it cannot be
                extracted.
              </p>
              <p>
                App registration can be immediately added and revoked from the
                Approov service, allowing tight control of which app versions
                can access your API.
              </p>
            </div>
          </Container>

          <Container>
            <h3 className="mt-8 text-2xl text-primary-500">
              Approov Capabilities
            </h3>
            <FeatureList />
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <TrialOffer />
              <p className="mt-0 font-bold text-center">
                Free 30-Day Evaluation • Access to All Features • No Credit Card
                Needed • Immediate Protection
              </p>
            </div>
          </Container>

          <Container>
            <h2 className="text-4xl text-center text-secondary-500">
              Integrate Your App
            </h2>
            <p>
              Integrating Approov into mobile applications is straightforward,
              and quickstart guides are provided for the popular platforms:
            </p>

            <QuickstartGrid className="px-4 my-2 lg:px-8" tag="mobile" />

            <p>
              After integration Approov tokens are automatically added to your
              API calls. The integration handles all of the analysis and
              measurement necessary to identify the app and the runtime
              environment to the Approov Cloud Service.
            </p>
            <p>
              After integrating the SDK into your app, register each app version
              with the Approov Cloud Service using the Approov command line
              tool. This extracts and registers the “DNA” of the app with the
              cloud service so that your app can be positively attested. Your
              app is then published as normal, and the enhanced security is
              transparent to your users.
            </p>
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <TrialOffer />
              <p className="mt-0 font-bold text-center">
                Free 30-Day Evaluation • Access to All Features • No Credit Card
                Needed • Immediate Protection
              </p>
            </div>
          </Container>

          <Container>
            <h2 className="text-4xl text-center text-secondary-500">
              Pin Your API Connections
            </h2>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-8">
              <div>
                <p>
                  Even though modern apps communicate using a secure TLS
                  connection they are still vulnerable to eavesdropping by a
                  Man-in-the-Middle (MitM) attacker. Such attacks are used to
                  reverse engineer an API protocol.
                </p>
                <p>
                  Employing certificate pinning prevents such attacks.
                  Unfortunately, this can be complex to implement in the app and
                  requires coordination with the backend API services. Approov
                  builds pinning right into the integration with support for
                  updating pinning over the air with no app update needed.
                  Certificate rotations can be handled cleanly, with no risk of
                  interruption to your customers.
                </p>
              </div>
              <div className="flex items-center justify-center">
                <Zoom>{PinningImage}</Zoom>
              </div>
            </div>
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <TrialOffer />
              <p className="mt-0 font-bold text-center">
                Free 30-Day Evaluation • Access to All Features • No Credit Card
                Needed • Immediate Protection
              </p>
            </div>
          </Container>

          <Container>
            <h2>Verify Your API Traffic</h2>
            <p>
              Integrating Approov into backend services is straightforward too,
              and quickstart guides are provided for popular platforms:{' '}
            </p>

            <QuickstartGrid className="px-4 my-2 lg:px-8" tag="backend" />

            <p>
              Token verification is simple due to the adoption of the industry
              standard JWT. You simply check that each token has been correctly
              signed with the secret for your account, and that it has not
              expired.
            </p>
            <p>
              Once the SDK has been integrated into your app, live tokens are
              added to your API request headers, and your backend API systems
              are enhanced to verify these tokens.
            </p>
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <TrialOffer />
              <p className="mt-0 font-bold text-center">
                Free 30-Day Evaluation • Access to All Features • No Credit Card
                Needed • Immediate Protection
              </p>
            </div>
          </Container>

          <Container>
            <h2 className="text-4xl text-center text-secondary-500">
              Operate With Confidence
            </h2>
            <p>
              We continuously use the aggregated and anonymized data that we
              have from all of our customers to identify new threats and enhance
              Approov's security capabilities accordingly.
            </p>
            <h3 className="mt-8 text-2xl text-primary-500">
              Monitor and Analyze Attestation Traffic
            </h3>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4 lg:mt-8 lg:gap-6">
              <div className="flex items-center lg:col-span-1">
                <p>
                  Live metrics are accumulated regarding device usage,
                  attestation forensics, and billing information. Both graphical
                  and email based reporting is available.
                </p>
              </div>
              <div className="flex items-center justify-center lg:col-span-3">
                <Zoom>{LiveMetricsImage}</Zoom>
              </div>
            </div>
          </Container>

          <Container>
            <h3 className="mt-8 text-2xl text-primary-500">
              Update Your Security Live
            </h3>
            <p>
              Approov detects potentially unsafe mobile device environments
              including device rooting/jailbreaking, emulator or debugger usage,
              malicious instrumentation frameworks, and cloned apps. Customers
              specify which policies should be enforced. Changes to security
              policies roll out immediately to active apps.
            </p>
            <p>
              Hackers continuously evolve their run time penetration techniques,
              and Approov keeps pace by providing security detection updates
              over the air without requiring app store updates. This live update
              service is also used to manage trust certificates and security
              policies.
            </p>
          </Container>

          <Container className="mb-8">
            <div className="flex flex-col items-center pt-12">
              <TrialOffer />
              <p className="mt-0 font-bold text-center">
                Free 30-Day Evaluation • Access to All Features • No Credit Card
                Needed • Immediate Protection
              </p>
            </div>
          </Container>
        </Section>
      </LandingPageLayout>

      <OfferDialog
        offer="mobapisec"
        open={isLearnDialogOpen}
        onClose={onCloseLearnDialog}
      />

      <OfferDialog
        offer="trial_signup"
        open={isTrialDialogOpen}
        onClose={onCloseTrialDialog}
      />
    </>
  )
}

export default PillarsPage
